import { FloorPlanTile, PanoTile } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useLayoutEffect, useState } from "react";
import {
  Group,
  InstancedMesh,
  Light,
  Mesh,
  Object3D,
  Points,
  Scene,
} from "three";

/**
 * From a generic ThreeJS object, create the corresponding base ThreeJS object
 * useful to decay custom ThreeJS objects to their base rendering
 *
 * @param object3d The object we want to create the clone to
 * @returns A basic threejs object to continue render the passed object
 */
function createBase3JSTypeClone(object3d: Object3D): Object3D {
  /** Tiles are cloned as themselves because we need the specific rendering logic underneath */
  if (object3d instanceof PanoTile) {
    return new PanoTile(object3d.material, object3d.texture, object3d.rect);
  }

  if (object3d instanceof FloorPlanTile) {
    return new FloorPlanTile(
      object3d.texture,
      object3d.geometry,
      object3d.material,
      object3d.depthLevel,
    );
  }

  // All objects that may have children or be rendered we need to decay
  // to the basic 3js types
  if (object3d instanceof InstancedMesh) {
    return new InstancedMesh(
      object3d.geometry,
      object3d.material,
      object3d.count,
    );
  } else if (object3d instanceof Mesh) {
    return new Mesh();
  } else if (object3d instanceof Points) {
    return new Points();
  } else if (object3d instanceof Group) {
    return new Group();
  } else if (object3d instanceof Scene) {
    return new Scene();
  } else if (object3d instanceof Light) {
    // retain specific light type
    return object3d.clone(false);
  }
  // Here we get only controls, we can decay them as Object3D as they do not render anything
  // the children will be handled separately by the createSnapshot function
  return new Object3D();
}

/**
 * Create a snapshot scene from an existing scene
 *
 * A snapshot scene is a new scene that re-use and do not own the resources from a previous
 * scene but clones all the 3d objects so they can live side by side with new objects
 *
 * This is useful if you want to generically continue to render what is currently on the screen
 * without knowing what it is but at the same time you want to change the scene to add other components
 *
 * The snapshot will lose all reactivity:
 * - The connection between the scene and the previous react components is lost so any change
 * on props from the previous component will not update this cene
 * - The threejs objects will decay to the basic threejs mesh, points, light etc.. so any special
 * logic like the auto update of tiles from Lotv LodPano will not happen anymore
 *
 * @param scene The input scene
 * @returns a new scene
 */
function createSnapshot(scene: Scene): Scene {
  function recursiveSnapshotClone<T extends Object3D>(
    object: T,
    parent?: Object3D,
  ): Object3D {
    const cloned = createBase3JSTypeClone(object);
    cloned.copy(object, false);
    if (parent) {
      parent.add(cloned);
    }
    for (const child of object.children) {
      recursiveSnapshotClone(child, cloned);
    }
    return cloned;
  }

  const snapshot = recursiveSnapshotClone(scene);
  if (!(snapshot instanceof Scene)) {
    throw Error(
      "recursiveSnapshotClone should create a Scene from an input Scene",
    );
  }
  return snapshot;
}

/**
 * Filter function to show everything in the snapshot, even objects with `visible` flag
 * set to false when the snapshot is created
 *
 * @returns True indipendently from the input data
 */
// eslint-disable-next-line func-style -- FIXME
export const showEverything = (): boolean => true;

type SnapshotRendererProps = {
  /** A filter function to select what to render from the snapshot */
  filter?(o: Object3D): boolean;
};

/**
 * This component will internally create a frozen clone of the current rendered scene
 * so a new Scene can be build and animated and can continue to render what was previously
 * visible without knowing the details of the previous scene.
 *
 * The snapshot will lose all reactivity:
 * - The connection between the scene and the previous react components is lost so any change
 * on props from the previous component will not update this cene
 * - The threejs objects will decay to the basic threejs mesh, points, light etc.. so any special
 * logic like the auto update of tiles from Lotv LodPano will not happen anymore
 *
 * @returns a non reactive snapshot of the currently rendered scene
 */
export function SnapshotRenderer({
  filter,
}: SnapshotRendererProps): JSX.Element {
  const appScene = useThree((s) => s.scene);

  const [snapshot] = useState(() => createSnapshot(appScene));

  useLayoutEffect(() => {
    if (!filter) return;
    snapshot.traverse((o) => (o.visible = filter(o)));
  }, [filter, snapshot]);

  return <primitive object={snapshot} />;
}
