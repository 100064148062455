import { isEqual } from "lodash";
import { Matrix4, Matrix4Tuple, Vector3 } from "three";
import { temporal } from "zundo";
import { create, useStore } from "zustand";

export enum PathAlignmentTool {
  /** Tool for translating/scaling a path relative to the sheet */
  alignPath = "alignPath",

  /** Tool for adjusting individual waypoints on the path */
  adjustPath = "adjustPath",
}

export type PathAlignmentContext = {
  /** Currently selected tool */
  activeTool: PathAlignmentTool;

  /** The global pose of the path */
  pose: Matrix4Tuple;

  /** The 3D positions of each pano, relative to the global pose */
  positions: Vector3[];

  /** Flag specifying which panos are locked */
  locked: boolean[];

  /** Initializes the state with a set of path positions */
  initialize(positions: Vector3[], pose: Matrix4Tuple): void;

  /** Changes the currently selected tool */
  setActiveTool(activeTool: PathAlignmentTool): void;

  /** Set the global pose of the path */
  setPose(pose: Matrix4Tuple): void;

  /** Set the positions of the placeholders */
  updatePositions(positions: Vector3[], locked: boolean[]): void;

  /** Set the locked flag for each pano in the path */
  setLocked(locked: boolean[]): void;
};

/** Context containing useful data for the Path alignment mode */
export const usePathAlignmentContext = create<PathAlignmentContext>()(
  temporal(
    (set) => ({
      activeTool: PathAlignmentTool.alignPath,
      pose: new Matrix4().toArray(),
      positions: [],
      locked: [],
      initialize(positions: Vector3[], pose: Matrix4Tuple) {
        set({
          positions,
          pose,
          locked: new Array(positions.length).fill(false),
        });
      },
      setActiveTool: (activeTool: PathAlignmentTool) => set({ activeTool }),
      setPose: (pose: Matrix4Tuple) => set({ pose }),
      updatePositions: (positions: Vector3[], locked: boolean[]) =>
        set({ positions, locked }),
      setLocked: (locked: boolean[]) => set({ locked }),
    }),
    {
      // Capture only state changes on the pose, positions and locked state
      partialize: (state) => {
        const { pose, positions, locked } = state;
        return { pose, positions, locked };
      },
      // Capture only states where something changed
      equality: isEqual,
    },
  ),
);

/**
 * @returns functions and state to control the Path Alignment undo/redo state
 *
 * NOTE: As the return type is complex and depend on the temporal arguments there's no easy type to express it
 */
// eslint-disable-next-line func-style -- FIXME
export const usePathAlignmentUndoRedo =
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  () => useStore(usePathAlignmentContext.temporal);
