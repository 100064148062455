import { useOrthophotoContext } from "@/components/common/orthophoto-context/orthophoto-context";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useCurrentScene } from "@/modes/mode-data-context";
import { useCached3DObject } from "@/object-cache";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { useBoxControlsClippingPlanes } from "@/utils/box-controls-context";
import { volumeFromPlanes } from "@/utils/volume-utils";
import { useToast } from "@faro-lotv/flat-ui";
import { AbortError, generateGUID } from "@faro-lotv/foundation";
import {
  isIElementGenericDataSession,
  isIElementGenericDataset,
  isIElementPointCloudStream,
} from "@faro-lotv/ielement-types";
import { assert, extractOrthophoto } from "@faro-lotv/lotv";
import {
  selectAncestor,
  selectIElementWorldTransform,
} from "@faro-lotv/project-source";
import { useCallback, useMemo } from "react";
import { Matrix4, Quaternion, Vector3 } from "three";
import { returnToPreviousMode } from "../export-mode-utils";
import { useOrthophotoViewDirection } from "./use-orthophoto-view-direction";

/**
 * @returns A callback to start an orthophoto generation
 */
export function useExportOrthophoto(): () => Promise<void> {
  const { main } = useCurrentScene();
  assert(
    main && isIElementPointCloudStream(main),
    "The overview image preview requires a point cloud",
  );

  const {
    startTaskInStore,
    updateTaskInStore,
    completeTask,
    failTask,
    cancelTask,
  } = useOrthophotoContext();

  const transform = useAppSelector(selectIElementWorldTransform(main.id));
  const pointCloud = useCached3DObject(main);
  const dataset = useAppSelector(
    selectAncestor(
      main,
      (e) => isIElementGenericDataset(e) || isIElementGenericDataSession(e),
    ),
  );

  const clippingPlanesBox = useBoxControlsClippingPlanes();

  // Update the camera position and orientation to frame the pointcloud
  const volume = useMemo(() => {
    if (!clippingPlanesBox) return;

    const v = volumeFromPlanes(clippingPlanesBox, transform);
    if (!v?.position || !v.rotation || !v.size) return;

    return {
      position: new Vector3(v.position.x, v.position.y, v.position.z),
      quaternion: new Quaternion(
        v.rotation.x,
        v.rotation.y,
        v.rotation.z,
        v.rotation.w,
      ),
      size: new Vector3(v.size.x, v.size.y, v.size.z),
    };
  }, [clippingPlanesBox, transform]);

  // View direction of the camera
  const viewDir = useOrthophotoViewDirection(volume);

  const { handleErrorWithToast } = useErrorHandlers();
  const { openToast } = useToast();

  const store = useAppStore();
  const dispatch = useAppDispatch();
  return useCallback(async () => {
    if (!volume || !viewDir || !dataset) {
      handleErrorWithToast({
        title: "Failed to generate the orthophoto",
        error: "Invalid parameters",
      });
      return;
    }

    const taskID = generateGUID();
    try {
      const controller = startTaskInStore(taskID, dataset.id);

      openToast({
        title: "Orthophoto generation",
        message:
          "Orthophoto creation started, see Cloud activity menu to see progress and download.",
      });

      const urlPromise = extractOrthophoto(
        pointCloud,
        new Matrix4().fromArray(transform.worldMatrix),
        volume,
        {
          viewDir,
          up: new Vector3(0, 1, 0),
          north: new Vector3(1, 0, 0),
        },
        controller.signal,
        (progress) => updateTaskInStore(taskID, progress),
      );
      returnToPreviousMode(store, dispatch);
      completeTask(taskID, await urlPromise);
    } catch (e) {
      if (e instanceof AbortError) {
        openToast({
          title: "Orthophoto generation was cancelled",
          variant: "warning",
        });
        cancelTask(taskID);
      } else {
        handleErrorWithToast({
          title: "Failed to generate the orthophoto",
          error: e,
        });
        failTask(taskID);
      }
    }
  }, [
    volume,
    viewDir,
    dataset,
    handleErrorWithToast,
    startTaskInStore,
    openToast,
    pointCloud,
    transform.worldMatrix,
    store,
    dispatch,
    completeTask,
    updateTaskInStore,
    cancelTask,
    failTask,
  ]);
}
