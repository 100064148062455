import {
  SceneContextMenuAnchorPosition,
  SceneContextMenuEvent,
  useSceneEvents,
} from "@/components/common/scene-events-context";
import { useIsPanoWithDepthAndReady } from "@/modes/walk-mode/walk-mode-hooks";
import { CadModelObject, PanoObject, PointCloudObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import {
  computeNdcCoordinates,
  isCadModelIntersection,
  useThreeEventTarget,
  useTypedEvent,
} from "@faro-lotv/app-component-toolbox";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useRef, useState } from "react";
import { MOUSE, Raycaster } from "three";

const STABLE_MOUSE_PIXELS = 2;

/** hit objects used in the hook for popup context menu in the 3D scene */
export type HitObjects = {
  /** point cloud that is rendered in the current scene */
  pointCloud?: PointCloudObject;

  /** cad model that is rendered in the current scene */
  cadModel?: CadModelObject;

  /** pano object that is rendered in the current scene */
  panoObject?: PanoObject;
};

/**
 * Hook creates the event handlers for popup context menu in the 3D scene
 */
export function useSceneContextMenuEventHandlers({
  pointCloud,
  cadModel,
  panoObject,
}: HitObjects): void {
  const activeTool = useAppSelector(selectActiveTool);
  const cadSceneEvents = useSceneEvents();

  const { isPanoWithDepth, isDepthReady } = useIsPanoWithDepthAndReady(
    panoObject?.iElement,
  );

  // A custom event handler is used to detect the right mouse up event and trigger
  // the context menu only if the mouse did not move too much in between.
  const contextMenuEvent = useRef<SceneContextMenuEvent | null>(null);

  const eventTarget = useThreeEventTarget();
  const camera = useThree((state) => state.camera);
  const [raycaster] = useState(() => new Raycaster());

  const setContextMenuAnchor = useCallback(
    (clientX: number, clientY: number) => {
      const object3D = pointCloud ?? cadModel ?? panoObject;
      if (!object3D || !!activeTool) return;

      const oldRealTimeRaycastingValue = pointCloud?.realTimeRaycasting;
      if (pointCloud) {
        pointCloud.realTimeRaycasting = false;
      }

      contextMenuEvent.current = null;
      const mouse = computeNdcCoordinates(clientX, clientY, eventTarget);
      raycaster.setFromCamera(mouse, camera);
      let object3dHits = raycaster.intersectObject(object3D);
      if (cadModel && pointCloud) {
        // use the first hit in case of pointcloud and cad model overlay
        const cadModelHits = raycaster.intersectObject(cadModel);
        if (
          !object3dHits.length ||
          (cadModelHits.length &&
            cadModelHits[0].distance < object3dHits[0].distance)
        ) {
          object3dHits = cadModelHits;
        }
      }

      if (pointCloud && oldRealTimeRaycastingValue) {
        pointCloud.realTimeRaycasting = oldRealTimeRaycastingValue;
      }

      contextMenuEvent.current = {
        x: clientX,
        y: clientY,
        objectId: undefined,
      };
      if (object3dHits.length) {
        contextMenuEvent.current.point = [
          object3dHits[0].point.x,
          object3dHits[0].point.y,
          object3dHits[0].point.z,
        ];

        if (isCadModelIntersection(object3dHits[0])) {
          contextMenuEvent.current.objectId = object3dHits[0].cadObjectId;
        }
      } else if (panoObject) {
        contextMenuEvent.current.panoDepthInfo = {
          isPanoWithDepth,
          isDepthReady,
        };
      }
    },
    [
      activeTool,
      cadModel,
      camera,
      eventTarget,
      isDepthReady,
      isPanoWithDepth,
      panoObject,
      pointCloud,
      raycaster,
    ],
  );

  const openContextMenu = useCallback(
    (clientX: number, clientY: number, allowUnselect: boolean) => {
      if (
        (!pointCloud && !cadModel && !panoObject) ||
        !!activeTool ||
        !contextMenuEvent.current
      ) {
        return;
      }

      if (
        Math.abs(clientX - contextMenuEvent.current.x) < STABLE_MOUSE_PIXELS &&
        Math.abs(clientY - contextMenuEvent.current.y) < STABLE_MOUSE_PIXELS
      ) {
        if (contextMenuEvent.current.objectId === undefined) {
          // at right click on empty space:
          // if context menu is open just close it, but not remove selection
          if (allowUnselect) {
            cadSceneEvents.locateCadObjectInTree.emit(undefined);
          }
        }

        if (
          contextMenuEvent.current.point ??
          contextMenuEvent.current.panoDepthInfo
        ) {
          cadSceneEvents.openSceneContextMenuFrom3DView.emit(
            contextMenuEvent.current,
          );
        }
      }
      contextMenuEvent.current = null;
    },
    [
      activeTool,
      cadModel,
      cadSceneEvents.locateCadObjectInTree,
      cadSceneEvents.openSceneContextMenuFrom3DView,
      panoObject,
      pointCloud,
    ],
  );

  useEffect(() => {
    // If there's an active tool, do not catch any event
    if (activeTool) return;

    // eslint-disable-next-line func-style -- FIXME
    const pointerDown = (event: PointerEvent): void => {
      if (event.button !== MOUSE.RIGHT) return;
      event.stopPropagation();
      setContextMenuAnchor(event.clientX, event.clientY);
    };

    // eslint-disable-next-line func-style -- FIXME
    const pointerUp = (event: PointerEvent): void => {
      if (event.button !== MOUSE.RIGHT) return;
      event.stopPropagation();

      openContextMenu(event.clientX, event.clientY, true);
    };

    eventTarget.addEventListener("pointerdown", pointerDown);
    eventTarget.addEventListener("pointerup", pointerUp);
    return () => {
      eventTarget.removeEventListener("pointerdown", pointerDown);
      eventTarget.removeEventListener("pointerup", pointerUp);
    };
  }, [eventTarget, setContextMenuAnchor, openContextMenu, activeTool]);

  // Handle context menu event from the overlay
  useTypedEvent<SceneContextMenuAnchorPosition>(
    cadSceneEvents.openSceneContextMenuFromOverlay,
    (event) => {
      setContextMenuAnchor(event.x, event.y);
      openContextMenu(event.x, event.y, false);
    },
  );
}
