import { useErrorHandlers } from "@/errors/components/error-handling-context";
import {
  projectPermissionError,
  unknownPageError,
} from "@/errors/unrecoverable-error";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import { setCurrentUser } from "@/store/user-slice";
import { assert } from "@faro-lotv/foundation";
import { AuthContext, useAuthContext } from "@faro-lotv/gate-keepers";
import {
  isCoreApiAuthenticationError,
  isRegisteredUserInfo,
  parseGenericUserInfoFromUserDetails,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";

interface Props {
  /** The project to ensure access for */
  projectId: string;
}

/**
 * Ensures that the current user has access to the specified HB project.
 *
 * Must be placed inside an `ApiClientContextProvider`.
 *
 * @returns the children if the user has access to the project or a login form otherwise.
 */
export function HBEnsureProjectAccess({
  projectId,
  children,
}: PropsWithChildren<Props>): JSX.Element | null {
  const originalAuthContext = useAuthContext();
  const { isLoggedIn, requestLogin } = originalAuthContext;

  const [allowProjectViewing, setAllowProjectViewing] =
    useState<boolean>(false);

  const { tokenProvider, coreApiClient: coreApi } = useApiClientContext();

  const currentUser = useAppSelector(selectCurrentUser);

  const dispatch = useAppDispatch();
  const { handleErrorWithPage } = useErrorHandlers();

  // Check the user login data trough the CoreApi
  useEffect(() => {
    async function checkPermissions(): Promise<void> {
      if (!!currentUser || !projectId) {
        return;
      }

      let projectInfo;
      try {
        projectInfo = await coreApi.getProjectInfo(projectId);
      } catch (error) {
        dispatch(setCurrentUser(undefined));

        if (!isLoggedIn) {
          // If the project info can't be loaded assume the project is private
          requestLogin();
        } else if (isCoreApiAuthenticationError(error)) {
          // If we are logged in, but the project is still not accessible, assume it is private without access
          handleErrorWithPage(projectPermissionError(error));
        } else {
          // If there is an unhandled error this is probably an invalid url
          handleErrorWithPage(unknownPageError(error));
        }
        return;
      }

      // In case the getProjectInfo call did not fail,
      // the current user is allowed to view the project (even if not logged in)
      setAllowProjectViewing(true);

      try {
        const { data } = await coreApi.getLoggedInUser();
        const userData = parseGenericUserInfoFromUserDetails(data);

        assert(isRegisteredUserInfo(userData), "User is not registered");

        dispatch(
          setCurrentUser({
            ...userData,
            projectPermissions: projectInfo.currentUserRights.permissions,
          }),
        );
      } catch {
        dispatch(setCurrentUser(undefined));
      }
    }

    checkPermissions();
  }, [
    coreApi,
    dispatch,
    projectId,
    currentUser,
    requestLogin,
    handleErrorWithPage,

    // Having isLoggedIn as dep makes sure the user data is requested once
    // the user has logged in through the UserModule
    isLoggedIn,
  ]);

  const value = useMemo<AuthContext>(
    () => ({
      ...originalAuthContext,
      tokenProvider,
    }),
    [originalAuthContext, tokenProvider],
  );

  if (!allowProjectViewing) {
    return null;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
