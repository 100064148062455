import { useAppSelector } from "@/store/store-hooks";
import { TreeData, selectProjectTree } from "@faro-lotv/project-source";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

type OpenMap = Record<string, boolean>;

export type TreeContextData = {
  /** The tree data */
  tree: TreeData[];

  /** The open state of the tree */
  openState: OpenMap;

  /** Set the open state of the tree */
  setOpenState(openState: OpenMap): void;
};

const TreeContext = createContext<TreeContextData | undefined>(undefined);

/**
 * @returns a context useful to set and read the current tree data
 */
export function useTreeContext(): TreeContextData {
  const context = useContext(TreeContext);
  if (!context) {
    throw new Error("TreeContext is not initialized.");
  }
  return context;
}

/**
 * @returns a component to keep the tree data synced
 */
export function TreeContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const tree = useAppSelector(selectProjectTree);

  const [openState, setOpenState] = useState<OpenMap>({
    [tree[0]?.id]: true,
  });

  const value = useMemo<TreeContextData>(
    () => ({
      tree,
      openState,
      setOpenState,
    }),
    [openState, tree],
  );

  return <TreeContext.Provider value={value}>{children}</TreeContext.Provider>;
}
