import { selectBackgroundTask } from "@/store/background-tasks/background-tasks-selector";
import { useAppSelector } from "@/store/store-hooks";
import {
  isCaptur3dFloorplanGenerationTask,
  isOrthophotoTask,
  isPointCloudExportTask,
} from "@/utils/background-tasks";
import { GUID, assert } from "@faro-lotv/foundation";
import { ProgressApiSupportedTaskTypes } from "@faro-lotv/service-wires";
import { FloorplanGenerationCard } from "./exports/floorplan-generation-card";
import { OrthophotoExportCard } from "./exports/orthophoto-export-card";
import { PointCloudExportCard } from "./exports/point-cloud-export-card";

type ExportTaskCardProps = {
  /** Id of the task to show an export card for */
  taskID: GUID;
};

/** @returns the export volume card with a button to download a pointcloud volume */
export function ExportTaskCard({
  taskID,
}: ExportTaskCardProps): JSX.Element | null {
  const task = useAppSelector(selectBackgroundTask(taskID));

  assert(task, "a TaskCard need to be created on an existing BackgroundTask");
  assert(
    isPointCloudExportTask(task) ||
      isOrthophotoTask(task) ||
      isCaptur3dFloorplanGenerationTask(task),
    "Not a valid export card",
  );

  switch (task.type) {
    case ProgressApiSupportedTaskTypes.pointCloudExport:
      return <PointCloudExportCard task={task} />;
    case ProgressApiSupportedTaskTypes.captur3dFloorplanGeneration:
      return <FloorplanGenerationCard task={task} />;
    case "Orthophoto":
      return <OrthophotoExportCard task={task} />;
  }
}
