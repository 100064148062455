import { selectBackgroundTask } from "@/store/background-tasks/background-tasks-selector";
import {
  addBackgroundTask,
  updateBackgroundTask,
} from "@/store/background-tasks/background-tasks-slice";
import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import {
  BackgroundTask,
  OrthophotoTask,
  isOrthophotoTask,
} from "@/utils/background-tasks";
import { assert } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import { DateTime } from "luxon";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type OrthophotoContext = {
  /** Callback to create and start an orthophoto task in the store */
  startTaskInStore(id: GUID, iElementId: GUID): AbortController;
  /** Callback to update an orthophoto task in the store */
  updateTaskInStore(id: GUID, progress: number): void;
  /** Callback to complete an orthophoto task in the store */
  completeTask(id: GUID, dataURL?: string): void;
  /** Callback to report an orthophoto task as failed */
  failTask(id: GUID): void;
  /** Callback to report an orthophoto task has been canceled */
  cancelTask(id: GUID): void;
  /** Get the abort controller for a specific task */
  getController(id: GUID): AbortController | undefined;
};

/** Context which provides the functionalities for managing orthophoto tasks */
export const OrthophotoContext = createContext<OrthophotoContext | undefined>(
  undefined,
);

/**
 * @returns a context useful to start and update orthophoto generation tasks in the store
 */
export function useOrthophotoContext(): OrthophotoContext {
  const context = useContext(OrthophotoContext);
  if (!context) {
    throw new Error("FileUploadContext is not initialized.");
  }
  return context;
}

/**
 * @returns a provider component for the OrthophotoContext
 */
export function OrthophotoContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const dispatch = useAppDispatch();
  const store = useAppStore();

  const [controllers] = useState<Map<GUID, AbortController>>(new Map());

  const startTaskInStore = useCallback(
    (id: GUID, iElementId: GUID) => {
      const isoDate = DateTime.now().toISO();

      const controller = new AbortController();
      controllers.set(id, controller);

      // Create background task
      const backgroundTask: OrthophotoTask = {
        id,
        createdAt: isoDate,
        changedAt: isoDate,
        type: "Orthophoto",
        devMessage: "Generating orthophoto",
        state: BackgroundTaskState.created,
        progress: 0,
        iElementId,
        shouldPreventWindowClose: true,
        metadata: {},
      };
      dispatch(addBackgroundTask(backgroundTask));
      return controller;
    },
    [controllers, dispatch],
  );

  const updateTaskInStore = useCallback(
    (id: GUID, progress: number) => {
      const task = selectBackgroundTask(id)(store.getState());
      assert(task && isOrthophotoTask(task), "Missing orthophoto task");
      // Update the percentage of completion and the changedAt time
      const updatedTask: BackgroundTask = {
        ...task,
        state: BackgroundTaskState.started,
        progress,
        changedAt: DateTime.now().toISO(),
      };
      dispatch(updateBackgroundTask(updatedTask));
    },
    [dispatch, store],
  );

  const completeTask = useCallback(
    (id: GUID, dataURL?: string) => {
      const task = selectBackgroundTask(id)(store.getState());
      assert(task && isOrthophotoTask(task), "Missing orthophoto task");
      // Flag the task as succeeded
      const updatedTask: OrthophotoTask = {
        ...task,
        state: BackgroundTaskState.succeeded,
        metadata: {
          downloadUrl: dataURL,
        },
        changedAt: DateTime.now().toISO(),
      };
      dispatch(updateBackgroundTask(updatedTask));
      controllers.delete(id);
    },
    [controllers, dispatch, store],
  );

  const failTask = useCallback(
    (id: GUID) => {
      const task = selectBackgroundTask(id)(store.getState());
      assert(task && isOrthophotoTask(task), "Missing orthophoto task");
      // Flag the task as failed
      const updatedTask: OrthophotoTask = {
        ...task,
        state: BackgroundTaskState.failed,
        changedAt: DateTime.now().toISO(),
      };
      dispatch(updateBackgroundTask(updatedTask));
      controllers.delete(id);
    },
    [controllers, dispatch, store],
  );

  const cancelTask = useCallback(
    (id: GUID) => {
      const task = selectBackgroundTask(id)(store.getState());
      assert(task && isOrthophotoTask(task), "Missing orthophoto task");

      // Flag the task as failed
      const updatedTask: OrthophotoTask = {
        ...task,
        state: BackgroundTaskState.aborted,
        changedAt: DateTime.now().toISO(),
      };
      dispatch(updateBackgroundTask(updatedTask));
      controllers.delete(id);
    },
    [controllers, dispatch, store],
  );

  const getController = useCallback(
    (id: GUID) => controllers.get(id),
    [controllers],
  );

  const contextValue = useMemo(
    () => ({
      startTaskInStore,
      updateTaskInStore,
      completeTask,
      failTask,
      cancelTask,
      getController,
    }),
    [
      cancelTask,
      completeTask,
      failTask,
      startTaskInStore,
      updateTaskInStore,
      getController,
    ],
  );

  return (
    <OrthophotoContext.Provider value={contextValue}>
      {children}
    </OrthophotoContext.Provider>
  );
}
