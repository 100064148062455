import { EventType } from "@/analytics/analytics-events";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectExclusiveModeCompletionAction } from "@/store/ui/ui-selectors";
import { setIsQuickHelpOpen } from "@/store/ui/ui-slice";
import { useLocalStorage } from "@faro-lotv/app-component-toolbox";
import {
  HelpBanner,
  HistoryRedoIcon,
  HistoryUndoIcon,
  ResetIcon,
  ToolButton,
  ToolGroup,
  Toolbar,
  TrajectoryAlignIcon,
  TrajectoryCorrectionIcon,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import { Stack, Tooltip } from "@mui/material";
import { useCallback, useEffect } from "react";
import {
  PathAlignmentTool,
  usePathAlignmentContext,
  usePathAlignmentUndoRedo,
} from "./path-alignment-mode-context";

/** Connect to keyboard events to implement undo-redo shortcuts */
function usePathAlignmentShortcuts(): void {
  const { undo, redo } = usePathAlignmentUndoRedo();

  useEffect(() => {
    // eslint-disable-next-line func-style -- FIXME
    const keyDown = (ev: KeyboardEvent): void => {
      if (ev.ctrlKey || ev.metaKey) {
        switch (ev.key) {
          case "z":
            Analytics.track(EventType.undoVideoModeEditorChanges);
            undo();
            break;
          case "y":
            Analytics.track(EventType.redoVideoModeEditorChanges);
            redo();
            break;
        }
      }
    };

    window.addEventListener("keydown", keyDown);

    return () => {
      window.removeEventListener("keydown", keyDown);
    };
  }, [redo, undo]);
}

/**
 * @returns Overlay to allow user to exit the path alignment mode and
 *   toggle between modes within the path alignment mode
 */
export function PathAlignmentModeOverlay(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const { activeTool, initialize, setActiveTool } = usePathAlignmentContext();

  const processing = !!useAppSelector(selectExclusiveModeCompletionAction);

  const { pastStates, futureStates, undo, redo } = usePathAlignmentUndoRedo();

  const [
    hasUserSeenPathAlignmentQuickHelp,
    setHasUserSeenPathAlignmentQuickHelp,
  ] = useLocalStorage<boolean>("hasUserSeenPathAlignmentQuickHelp");

  useEffect(() => {
    if (!hasUserSeenPathAlignmentQuickHelp) {
      // If the user is in the Path Alignment for the first time and hasn't seen the quick help once, then open the quick help tool
      dispatch(setIsQuickHelpOpen(true));
    }
    // Once the user is in this component, then mark the user is not in path alignment for the first time
    setHasUserSeenPathAlignmentQuickHelp(true);
  }, [
    dispatch,
    hasUserSeenPathAlignmentQuickHelp,
    setHasUserSeenPathAlignmentQuickHelp,
  ]);

  const resetPath = useCallback(() => {
    const originalState = pastStates.at(0);
    assert(
      originalState?.positions && originalState.pose,
      "Invalid initial state",
    );
    initialize(originalState.positions, originalState.pose);
  }, [initialize, pastStates]);

  usePathAlignmentShortcuts();

  const activateSheetAlignmentMode = useCallback(() => {
    Analytics.track(EventType.selectVideomodeEditorAlignmentTool);
    setActiveTool(PathAlignmentTool.alignPath);
  }, [setActiveTool]);
  const activatePathAdjustmentMode = useCallback(() => {
    Analytics.track(EventType.selectVideoModeEditorTrajectoryTool);
    setActiveTool(PathAlignmentTool.adjustPath);
  }, [setActiveTool]);

  const bannerText =
    activeTool === PathAlignmentTool.alignPath
      ? "Add up to two anchor points to scale, rotate, and translate the path"
      : "Click and drag a waypoint to adjust the trajectory";

  if (processing) return null;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      {/* first empty column of a three column layout */}
      <div />

      <Stack>
        <HelpBanner>{bannerText}</HelpBanner>
      </Stack>

      <Stack justifyContent="center">
        <Toolbar>
          <ToolGroup>
            <Tooltip title="Align to sheet" placement="left">
              <ToolButton
                value={PathAlignmentTool.alignPath}
                selected={activeTool === PathAlignmentTool.alignPath}
                onClick={activateSheetAlignmentMode}
              >
                <TrajectoryAlignIcon sx={{ width: "24px", height: "24px" }} />
              </ToolButton>
            </Tooltip>
            <Tooltip title="Adjust trajectory" placement="left">
              <ToolButton
                value={PathAlignmentTool.adjustPath}
                selected={activeTool === PathAlignmentTool.adjustPath}
                onClick={activatePathAdjustmentMode}
              >
                <TrajectoryCorrectionIcon
                  sx={{ width: "24px", height: "24px" }}
                />
              </ToolButton>
            </Tooltip>
          </ToolGroup>
          <ToolGroup>
            <Tooltip title="Undo changes" placement="left">
              <ToolButton
                value="undo-changes"
                onClick={() => {
                  Analytics.track(EventType.undoVideoModeEditorChanges);
                  undo();
                }}
                disabled={pastStates.length === 0}
              >
                <HistoryUndoIcon sx={{ width: "24px", height: "24px" }} />
              </ToolButton>
            </Tooltip>
            <Tooltip title="Redo changes" placement="left">
              <ToolButton
                // The toggle button is used as a regular button here, so the styling of the button group can be used
                value="redo-changes"
                onClick={() => {
                  Analytics.track(EventType.redoVideoModeEditorChanges);
                  redo();
                }}
                disabled={futureStates.length === 0}
              >
                <HistoryRedoIcon sx={{ width: "24px", height: "24px" }} />
              </ToolButton>
            </Tooltip>
            <Tooltip title="Reset changes" placement="left">
              <ToolButton
                // The toggle button is used as a regular button here, so the styling of the button group can be used
                value="reset-changes"
                onClick={() => {
                  Analytics.track(EventType.resetVideoModeEditorChanges);
                  resetPath();
                }}
                disabled={pastStates.length === 0}
              >
                <ResetIcon sx={{ width: "24px", height: "24px" }} />
              </ToolButton>
            </Tooltip>
          </ToolGroup>
        </Toolbar>
      </Stack>
    </Stack>
  );
}
