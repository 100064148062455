import { useOrthophotoContext } from "@/components/common/orthophoto-context/orthophoto-context";
import { useAppSelector } from "@/store/store-hooks";
import { OrthophotoTask } from "@/utils/background-tasks";
import { downloadFile } from "@/utils/download";
import { selectIElement } from "@faro-lotv/project-source";
import {
  BackgroundTaskState,
  isBackgroundTaskActive,
} from "@faro-lotv/service-wires";
import { useCallback, useMemo } from "react";
import { CardProgress } from "../../card-progress";
import {
  CardAction,
  GenericCardLayout,
} from "../../layouts/generic-card-layout";

type OrthophotoExportCardProps = {
  task: OrthophotoTask;
};

/** @returns The card for the orthophoto generation task */
export function OrthophotoExportCard({
  task,
}: OrthophotoExportCardProps): JSX.Element {
  const { iElementId, metadata, state } = task;
  const refElement = useAppSelector(selectIElement(iElementId));
  const name = useMemo(
    () => refElement?.name ?? "Orthophoto",
    [refElement?.name],
  );

  const downloadOrthophoto = useCallback(() => {
    if (metadata.downloadUrl !== undefined) {
      downloadFile(metadata.downloadUrl, `${name}.png`);
    }
  }, [metadata.downloadUrl, name]);

  const { getController } = useOrthophotoContext();
  const cancelOrthophoto = useCallback(() => {
    const controller = getController(task.id);
    if (!controller) return;
    controller.abort();
  }, [getController, task.id]);

  const action = useMemo<CardAction | undefined>(() => {
    if (metadata.downloadUrl) {
      return {
        name: "Download",
        callback: downloadOrthophoto,
      };
    } else if (
      task.state === BackgroundTaskState.started ||
      task.state === BackgroundTaskState.created
    ) {
      return { name: "Cancel", callback: cancelOrthophoto };
    }
  }, [cancelOrthophoto, downloadOrthophoto, metadata.downloadUrl, task.state]);

  return (
    <GenericCardLayout
      name={name}
      subText="Orthophoto"
      startTime={task.createdAt}
      action={action}
    >
      {isBackgroundTaskActive(state) && (
        <CardProgress label="Generating..." task={task} />
      )}
    </GenericCardLayout>
  );
}
