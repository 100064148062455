import { RootState } from "./store";

/** A selector taking arguments. */
export type CurriedAppSelector<Result, Params extends readonly unknown[]> = (
  ...args: Params
) => (state: RootState) => Result;

/**
 * Transform a selector which takes multiple arguments into a version which first takes the arguments and then only the state.
 *
 * Use this function in combination with `reselect` to create selectors which are compatible with `useAppSelector`.
 *
 * @param selector The selector to curry
 * @returns The curried selector
 * @see https://reselect.js.org/FAQ#how-can-i-make-a-curried-selector
 */
export function curryAppSelector<
  Result,
  Params extends readonly unknown[],
  AdditionalFields,
>(
  selector: ((state: RootState, ...args: Params) => Result) & AdditionalFields,
): CurriedAppSelector<Result, Params> & AdditionalFields {
  // eslint-disable-next-line func-style -- FIXME
  const curriedSelector = (...args: Params) => {
    return (state: RootState) => {
      return selector(state, ...args);
    };
  };
  return Object.assign(curriedSelector, selector);
}
