import { AlignmentTransform } from "@/alignment-tool/store/alignment-slice";
import { alignmentTransformToMatrix4 } from "@/alignment-tool/utils/alignment-transform";
import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import { useObjectView } from "@/hooks/use-object-view";
import { PointCloudObject } from "@/object-cache";
import {
  Canvas,
  EffectPipeline,
  RenderPass,
} from "@faro-lotv/app-component-toolbox";
import { AdaptivePointsMaterial } from "@faro-lotv/lotv";
import { useTheme } from "@mui/material";
import { useThree } from "@react-three/fiber";
import {
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import {
  Box3,
  Color,
  Group,
  Matrix4,
  PerspectiveCamera,
  Vector2,
  Vector3,
} from "three";
import { PointCloudDefaultEffects } from "./pointcloud-renderer";

export type PointCloudPreviewActions = {
  /** Callback to update the preview with a new transform */
  updatePreview(transform: Matrix4): void;
};

export type PointCloudPreviewProps = {
  /** The point cloud to render in the preview */
  pointCloud: PointCloudObject;
  /** The initial pose of the point cloud */
  initialPose: AlignmentTransform | undefined;
  /** The actions that can be executed from outside this component */
  actions: RefObject<PointCloudPreviewActions>;
};

/**
 * @returns A canvas containing the preview of the input point cloud
 */
export function PointCloudPreview({
  pointCloud,
  initialPose,
  actions,
}: PointCloudPreviewProps): JSX.Element {
  const { palette } = useTheme();
  const box = useObjectBoundingBox(pointCloud);

  return (
    <Canvas
      style={{ width: "100%", height: "100%" }}
      onCreated={(state) =>
        (state.scene.background = new Color(palette.gray200))
      }
      frameloop="demand"
    >
      <PreviewRenderer
        pointCloud={pointCloud}
        initialPose={initialPose}
        actions={actions}
        box={box ?? new Box3()}
      />
    </Canvas>
  );
}

type PreviewRendererProps = PointCloudPreviewProps & {
  /** The bounding box of the point cloud, without taking into account the pose */
  box: Box3;
};

function PreviewRenderer({
  pointCloud,
  actions,
  box,
  initialPose,
}: PreviewRendererProps): JSX.Element {
  const { gl, scene, camera, invalidate } = useThree();

  const size = useMemo(() => gl.getSize(new Vector2()), [gl]);

  // Create a view on the input data (if possible)
  const view = useObjectView(pointCloud);
  useEffect(() => {
    view.position.copy(pointCloud.position);
    view.quaternion.copy(pointCloud.quaternion);
    view.scale.copy(pointCloud.scale);
    const material = new AdaptivePointsMaterial({ maxSize: 2, minSize: 6 });
    if (pointCloud.monochrome) {
      material.vertexColors = false;
      material.color = new Color("white");
    }
    view.material = material;
  }, [pointCloud, view]);

  // Set up callback used to update the rendering when new points arrive
  useEffect(() => {
    // eslint-disable-next-line func-style -- FIXME
    const updateView = (): void => {
      view.updateCamera(camera, size);
      invalidate();
    };
    view.allPointsReceived.on(updateView);
    return () => {
      view.allPointsReceived.off(updateView);
    };
  }, [camera, invalidate, scene, size, view]);

  const groupRef = useRef<Group>(null);
  const updatePreview = useCallback(
    (transform: Matrix4) => {
      if (!groupRef.current) return;
      if (!(camera instanceof PerspectiveCamera)) return;
      const group = groupRef.current;
      transform.decompose(group.position, group.quaternion, group.scale);
      group.updateMatrixWorld();

      const center = box.getCenter(new Vector3()).applyMatrix4(transform);
      const max = box.max.clone().applyMatrix4(transform);

      camera.fov = 45;
      const radius = new Vector3().subVectors(max, center);
      const length = radius.length() * 2;
      const distance = length / Math.tan((camera.fov * Math.PI) / 360);
      camera.position
        .copy(center)
        .add(radius.setY(0).normalize().multiplyScalar(length));
      camera.lookAt(center);

      camera.far = 3 * distance;
      camera.updateProjectionMatrix();
      camera.updateMatrixWorld();

      view.updateMatrixWorld();
      view.updateCamera(camera, size);

      invalidate();
    },
    [box, camera, invalidate, size, view],
  );
  useImperativeHandle(actions, () => ({ updatePreview }));

  useEffect(() => {
    if (!initialPose) return;
    updatePreview(alignmentTransformToMatrix4(initialPose));
  }, [initialPose, updatePreview]);

  return (
    <>
      <group ref={groupRef} {...initialPose}>
        <primitive object={view} />
      </group>
      <EffectPipeline>
        <RenderPass />
        <PointCloudDefaultEffects pointCloud={view} />
      </EffectPipeline>
    </>
  );
}
