import {
  CreateAreaEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import {
  EmbeddedToolbar,
  EmbeddedToolbarButton,
} from "@/components/ui/embedded-toolbar";
import { Features } from "@/store/features/features";
import { selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import { CombinedUploadProgress } from "@/utils/combined-upload-progress";
import {
  CropIcon,
  Dropdown,
  Option as DropdownOption,
  DropHandler,
  ExportIcon,
  FaroButton,
  FaroText,
  FaroTooltip,
  neutral,
  RotateClockwiseIcon,
  TextField,
  ToolGroup,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { getFileExtension } from "@faro-lotv/foundation";
import { Box, Card, Stack } from "@mui/material";
import {
  ChangeEvent,
  DragEvent as ReactDragEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  MAX_ALLOWED_IMG_HEIGHT,
  MAX_ALLOWED_IMG_WIDTH,
  SupportedImgSheetFileExtensionList,
} from "./create-area-utils";

interface EditImageSubmenuProps {
  /** Callback executed to enable/disable crop */
  onEnableCrop?(): void;

  /** Callback executed on click rotation button */
  onRotate?(): void;

  /** if true buttons in toolbar are disabled */
  disabled: boolean;
}

/** @returns the submenu for the clip scene tool */
export function EditImageSubmenu({
  onEnableCrop,
  onRotate,
  disabled,
}: EditImageSubmenuProps): JSX.Element {
  return (
    <EmbeddedToolbar
      isActive
      sx={{
        backgroundColor: neutral[999],
        borderRadius: "6px",
      }}
    >
      <ToolGroup orientation="vertical">
        <FaroTooltip title="Rotate image" placement="right">
          <EmbeddedToolbarButton
            size="small"
            onClick={onRotate}
            value="Rotate image"
            disabled={disabled}
            vertical
          >
            <RotateClockwiseIcon />
          </EmbeddedToolbarButton>
        </FaroTooltip>

        <FaroTooltip title="Crop image" placement="right">
          <EmbeddedToolbarButton
            size="small"
            onClick={onEnableCrop}
            value="Crop image"
            disabled={disabled}
            vertical
          >
            {
              // image cropping will be implemented in https://faro01.atlassian.net/browse/CADBIM-978
              // at the moment that button is hidden from users by feature flag "Add Area"
            }
            <CropIcon />
          </EmbeddedToolbarButton>
        </FaroTooltip>
      </ToolGroup>
    </EmbeddedToolbar>
  );
}

interface OpenImageFileSelectControlProps {
  /** Callback executed on set image file */
  onSetFile(file?: File): void;
}

/** @returns control for selection file with sheet image */
export function OpenImageFileSelectControl({
  onSetFile,
}: OpenImageFileSelectControlProps): JSX.Element {
  const [fileInputEl, setFileInputEl] = useState<HTMLElement | null>(null);
  const [isFileExplorerOpen, setIsFileExplorerOpen] = useState(false);

  const setFileFromDrop = useCallback(
    (e: ReactDragEvent<HTMLElement>) => {
      e.preventDefault();
      // Double check that we are importing one and only one file
      if (e.dataTransfer.files.length !== 1) return;

      onSetFile(e.dataTransfer.files[0]);
    },
    [onSetFile],
  );

  const openFileExplorer = useCallback(() => {
    fileInputEl?.click();
    setIsFileExplorerOpen(true);
  }, [fileInputEl]);

  const setFileFromDialog = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsFileExplorerOpen(false);
      const file = e.target.files?.[0];
      if (!file) {
        return;
      }

      Analytics.track<CreateAreaEventProperties>(EventType.setFloorImage, {
        via: "custom-image",
        extension: getFileExtension(file.name) ?? "",
      });

      onSetFile(file);
    },
    [onSetFile],
  );

  const closeFileDialog = useCallback(() => {
    setIsFileExplorerOpen(false);
  }, []);

  useEffect(() => {
    fileInputEl?.addEventListener("cancel", closeFileDialog);

    return () => {
      fileInputEl?.removeEventListener("cancel", closeFileDialog);
    };
  });

  return (
    <DropHandler
      sx={{
        height: "calc(100vh - 38rem)",
        maxHeight: "42.5rem",
        minHeight: "12.5rem",
        ":hover": {
          border: "solid",
          borderColor: "primary.main",
          borderRadius: "0.25rem",
        },
      }}
      onDrop={setFileFromDrop}
      canBeDropped={(e) =>
        e.dataTransfer.items.length === 1 && !isFileExplorerOpen
      }
      enabled
      onClick={openFileExplorer}
    >
      <Card
        sx={{
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          padding: 1,
          boxShadow: "none",
          ":hover": {
            backgroundColor: ({ palette }) => `${palette.gray500}26`,
          },
        }}
      >
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="3.75rem"
          height="3.75rem"
          borderRadius="50%"
          bgcolor={neutral[100]}
        >
          <ExportIcon sx={{ fontSize: "2rem" }} />
        </Box>
        <Stack
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="center"
          alignItems="center"
        >
          <FaroText variant="bodyM">Drag your data here, or&nbsp;</FaroText>
          <FaroText variant="hyperLink">
            <input
              aria-label="select sheet file"
              ref={setFileInputEl}
              type="file"
              style={{ display: "none" }}
              onChange={setFileFromDialog}
              multiple={false}
            />
            <FaroText variant="hyperLink" sx={{ cursor: "pointer" }}>
              select file
            </FaroText>
          </FaroText>
          <FaroText variant="bodyM"> &nbsp;to upload</FaroText>
        </Stack>
        <FaroText variant="placeholder">
          {`Supported formats ${SupportedImgSheetFileExtensionList.join(", ")}`}
        </FaroText>
        <FaroText variant="placeholder">
          Maximum resolution: {MAX_ALLOWED_IMG_HEIGHT}x{MAX_ALLOWED_IMG_WIDTH}{" "}
          px
        </FaroText>
      </Card>
    </DropHandler>
  );
}

interface SheetUploadControlsProps {
  /** File currently selected */
  file?: File;

  /** Progress of the upload tasks */
  uploadProgress?: CombinedUploadProgress;

  /** The validation error to show for the input name */
  inputNameError?: string;

  /** Name of the Area and ImgSheet to create */
  inputName: string;

  /** Set the name of the new area */
  onSetInputName(name: string): void;

  /** total number of pages in PDF document; zero if file is not *.pdf */
  numberOfPdfPages?: number;

  /** selected page number if file is PDF (numerated from 1 to numberOfPdfPages). Ignored if file is not PDF */
  selectedPdfPage: number;

  /**
   * callback used to update selection of the page from multi-pages PDF file
   *
   * @param pageNumber selected page number (numerated from 1 to numberOfPdfPages)
   */
  onUpdateSelectedPdfPage(pageNumber: number): void;

  /** Function that resets the input file and cancels the upload if the user selects so in the dialog */
  onCancelUpload(): Promise<void>;

  /** Function that creates a new Area Section, uploads the file and creates a new ImgSheet */
  onCreateSheet(): Promise<void>;
}

/** @returns control bar for setting image name, page, re-selection image and starting upload */
export function SheetUploadControls({
  file,
  inputName,
  uploadProgress,
  inputNameError,
  onSetInputName,
  numberOfPdfPages,
  selectedPdfPage,
  onUpdateSelectedPdfPage,
  onCancelUpload,
  onCreateSheet,
}: SheetUploadControlsProps): JSX.Element {
  // create array of entries for dropdown, starting from 1 as natural way for multi-pages documents
  const pagesSelector: DropdownOption[] | undefined = useMemo(
    () =>
      numberOfPdfPages
        ? Array.from({ length: numberOfPdfPages }, (_, index) => ({
            key: `${index + 1}`,
            value: `${index + 1}`,
            label: `${index + 1}`,
          }))
        : undefined,
    [numberOfPdfPages],
  );

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" width="60%" gap="16px">
          <Stack width="70%">
            <TextField
              label="Sheet name"
              placeholder="First level"
              fullWidth
              disabled={!!uploadProgress}
              sx={{
                height: "2rem",
                backgroundColor: "white",
                width: "100%",
              }}
              error={inputNameError}
              onTextChanged={onSetInputName}
              text={inputName}
              inputProps={{
                "aria-label": "sheet name",
                onBlur: () => {
                  Analytics.track<CreateAreaEventProperties>(
                    EventType.changeFloorImage,
                  );
                },
              }}
            />
          </Stack>
          {file && (
            <Stack width="30%" marginBottom="3px">
              {pagesSelector && (
                <Dropdown
                  label="PDF page"
                  options={pagesSelector}
                  disabled={!!uploadProgress}
                  sx={{
                    height: "2rem",
                    mb: "3px",
                    bottom: "3px",
                  }}
                  value={selectedPdfPage.toString()}
                  onChange={(event) => {
                    onUpdateSelectedPdfPage(parseInt(event.target.value, 10));
                  }}
                />
              )}
            </Stack>
          )}
        </Stack>

        {file && (
          <Stack direction="row" justifyContent="end">
            <FaroButton
              aria-label="select a different image"
              variant="secondary"
              sx={{ mt: "21px", mr: "16px", height: "2rem" }}
              disabled={!!uploadProgress}
              onClick={() => {
                Analytics.track<CreateAreaEventProperties>(
                  EventType.changeFloorImage,
                );

                onCancelUpload();
              }}
            >
              Select different file
            </FaroButton>

            <FaroButton
              aria-label="upload"
              sx={{ mt: "21px", height: "2rem" }}
              disabled={!!uploadProgress}
              onClick={onCreateSheet}
              isLoading={!!uploadProgress}
            >
              {uploadProgress ? "Importing" : "Import"}
            </FaroButton>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

interface SheetImagePreviewProps {
  /** File currently selected */
  file: File;

  /** rotation angle in degrees  */
  rotation: number;

  /**
   * method to set rotation angle
   *
   * @param rotation rotation angle in degrees
   */
  setRotation(rotation: number): void;

  /** ratio width/height - used to scale rotated image in preview  */
  widthToHeightRatio: number;

  /** Progress of the upload tasks */
  uploadProgress?: CombinedUploadProgress;

  /**
   * method to set rotation angle
   *
   * @param rotation rotation angle in degrees
   */
  setShowImagePreview(showImagePreview: boolean): void;
}

/** @returns control to preview sheet image */
export function SheetImagePreview({
  file,
  rotation,
  setRotation,
  widthToHeightRatio,
  uploadProgress,
  setShowImagePreview,
}: SheetImagePreviewProps): JSX.Element {
  // This is needed to avoid continuously creating a new URL damaging performance
  const fileSource = useMemo(() => URL.createObjectURL(file), [file]);

  const showEditButtons = useAppSelector(selectHasFeature(Features.AddArea));

  // eslint-disable-next-line func-style -- FIXME
  const rotateImage = (): void => {
    setRotation(rotation + 90);
  };

  const scale = useMemo(() => {
    // we should never increase original size of image, only reduce if it doesn't fit to original box
    const reducedScaleFactor =
      widthToHeightRatio < 1 ? widthToHeightRatio : 1 / widthToHeightRatio;

    return rotation % 180 === 0 ? 1 : reducedScaleFactor;
  }, [rotation, widthToHeightRatio]);

  return (
    <Stack
      direction="row"
      width="100%"
      bgcolor={neutral[0]}
      border={3}
      borderColor={neutral[300]}
      borderRadius={2}
    >
      <Stack width="68px" />
      <Stack width="100%" display="flex" alignItems="center">
        <Box
          component="img"
          src={fileSource}
          alt={file.name}
          display="flex"
          style={{
            transform: `rotate(${rotation}deg) scale(${scale})`,
            transformOrigin: "center",
            objectFit: "contain",
            marginTop: "1rem",
            marginBottom: "1rem",
            width: "80%",
          }}
          onClick={() => setShowImagePreview(true)}
        />
      </Stack>
      <Stack width="68px" alignItems="center" justifyContent="center">
        {showEditButtons && (
          <EditImageSubmenu
            onRotate={rotateImage}
            disabled={!!uploadProgress}
          />
        )}
      </Stack>
    </Stack>
  );
}
