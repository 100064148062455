import { debounce } from "lodash";
import { useLayoutEffect, useState } from "react";

export type ComponentSizes = {
  /** The width of the tracked container */
  width: number;

  /** The height of the tracked container */
  height: number;
};

/** Delay between multiple resize reporting to allow smooth animations of tracked elements */
const RESIZE_DEBOUNCE_DELAY = 100;

/**
 * @returns the tracked element width and height
 * @param element to track
 */
export function useTrackElementSizes(element?: HTMLElement): ComponentSizes {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    if (!element) return;
    // eslint-disable-next-line func-style -- FIXME
    const onResize = (): void => {
      const { width, height } = element.getBoundingClientRect();
      setWidth(width);
      setHeight(height);
    };
    onResize();
    const observer = new ResizeObserver(
      debounce(onResize, RESIZE_DEBOUNCE_DELAY),
    );
    observer.observe(element);
    return () => observer.disconnect();
  }, [element]);

  return {
    width,
    height,
  };
}
