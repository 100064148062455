import { PointCloudObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import {
  CopyToScreenPass,
  EffectPipelineWithSubScenes,
  FilteredRenderPass,
  SubScene,
} from "@faro-lotv/app-component-toolbox";
import { AbstractRenderingPolicy } from "@faro-lotv/lotv";
import { debounce } from "lodash";
import { useEffect, useMemo } from "react";
import { Object3D } from "three";
import {
  selectPointCloudStreamIdsForHoveredEntity,
  selectPointCloudStreamIdsForSelectedEntity,
} from "../store/data-preparation-ui/data-preparation-ui-selectors";

type RevisionRenderingPipelineProps = {
  pointCloudObjects: PointCloudObject[];
};

/**
 * @returns A custom rendering pipeline for more efficient point cloud rendering.
 */
export function RevisionRenderingPipeline({
  pointCloudObjects,
}: RevisionRenderingPipelineProps): JSX.Element {
  const renderingPolicy = useMemo(
    () => new MultiCloudRenderingPolicy(pointCloudObjects),
    [pointCloudObjects],
  );

  // Invalidate scene when point cloud colors change
  const hoveredPointClouds = useAppSelector(
    selectPointCloudStreamIdsForHoveredEntity,
  );
  const selectedPointClouds = useAppSelector(
    selectPointCloudStreamIdsForSelectedEntity,
  );
  useEffect(() => {
    renderingPolicy.invalidateScene();
  }, [hoveredPointClouds, selectedPointClouds, renderingPolicy]);

  return (
    <EffectPipelineWithSubScenes>
      <SubScene
        filter={(obj: Object3D) => isObjPointCloudPoint(obj)}
        renderingPolicy={renderingPolicy}
      ></SubScene>
      <FilteredRenderPass
        filter={(obj: Object3D) => !isObjPointCloudPoint(obj)}
        clear={false}
        clearDepth={false}
      />
      <CopyToScreenPass />
    </EffectPipelineWithSubScenes>
  );
}

const SUBSAMPLED_RENDER_FRACTION = 0.1;
const SCENE_CHANGE_WAIT_MS = 300;
const SCENE_CHANGE_MAX_DELAY_MS = 700;

class MultiCloudRenderingPolicy extends AbstractRenderingPolicy {
  private sceneChangedBuffer = false;

  constructor(public pointCloudObjects: PointCloudObject[]) {
    super();

    for (const pointCloud of this.pointCloudObjects) {
      pointCloud.nodeReady.on(this.queueSceneChange);
    }
  }

  /** Queue a scene change, which is debounced to reduce the scene invalidations */
  queueSceneChange = debounce(
    () => {
      this.sceneChangedBuffer = true;
    },
    SCENE_CHANGE_WAIT_MS,
    { maxWait: SCENE_CHANGE_MAX_DELAY_MS },
  );

  invalidateScene(): void {
    this.sceneChangedBuffer = true;
  }

  /** @inheritdoc */
  override sceneChanged(): boolean {
    const hasChanged = super.sceneChanged() || this.sceneChangedBuffer;
    if (hasChanged) {
      // Change registered, no need to queue up another one unless there are more changes
      this.queueSceneChange.cancel();
    }
    this.sceneChangedBuffer = false;
    return hasChanged;
  }

  protected modelChanged(): boolean {
    return false;
  }

  /** @inheritdoc */
  override onCameraStartedMoving(): void {
    this.enableFastRendering();
    super.onCameraStartedMoving();
    this.invalidateScene();
  }

  /** @inheritdoc */
  override onCameraStoppedMoving(): void {
    this.disableFastRendering();
    super.onCameraStoppedMoving();
    this.invalidateScene();
  }

  /** Reduce visual quality in favor of better performance. */
  private enableFastRendering(): void {
    for (const pointCloud of this.pointCloudObjects) {
      pointCloud.setSubsampledRenderingFraction(SUBSAMPLED_RENDER_FRACTION);
      pointCloud.setSubsampledRenderingOn(true);
    }
  }

  /** Use high-quality rendering, but with worse performance. */
  private disableFastRendering(): void {
    for (const pointCloud of this.pointCloudObjects) {
      pointCloud.setSubsampledRenderingOn(false);
    }
  }
}
