import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAppSelector } from "@/store/store-hooks";
import { selectCanUploadCad } from "@/store/subscriptions/subscriptions-selectors";
import { DropHandler, useToast } from "@faro-lotv/flat-ui";
import {
  PropsWithChildren,
  DragEvent as ReactDragEvent,
  useCallback,
} from "react";
import { isSupportedCADFileExtension } from "./point-cloud-file-upload-context/cad-upload-utils";
import { useElementFileUploadContext } from "./point-cloud-file-upload-context/element-file-upload-context";
import {
  isSupportedPCFileExtension,
  useCanImportPointCloud,
} from "./point-cloud-file-upload-context/point-cloud-upload-utils";

/**
 * @returns A dropzone prepared to accept only one file at a time. Files dropped here will be
 * uploaded and imported by the app.
 */
export function Upload3dDataDropHandler({
  children,
}: PropsWithChildren): JSX.Element {
  const { handleErrorWithDialog } = useErrorHandlers();

  const { setPointCloudFile, setCadFile } = useElementFileUploadContext();

  const { openToast } = useToast();

  const canImportCAD = useAppSelector(selectCanUploadCad);

  // eslint-disable-next-line func-style -- FIXME
  const onDrop = (e: ReactDragEvent<HTMLElement>): void => {
    e.preventDefault();

    // Double check that we are importing one and only one file
    if (e.dataTransfer.files.length !== 1) return;
    try {
      const fileName = e.dataTransfer.files[0].name;

      if (isSupportedPCFileExtension(fileName)) {
        setPointCloudFile(e.dataTransfer.files[0]);
      } else if (canImportCAD && isSupportedCADFileExtension(fileName)) {
        setCadFile(e.dataTransfer.files[0]);
      } else {
        openToast({
          title: "Failed to import file",
          message: "The file extension is not supported.",
          variant: "error",
        });
      }
    } catch (error) {
      handleErrorWithDialog({ title: "3D Data upload", error });
    }
  };

  // If the import point cloud feature is enabled and there are no background tasks being executed, enable the drop handler.
  const shouldEnableDropHandler = useCanImportPointCloud();

  // Only one file can be uploaded
  const canBeDropped = useCallback(
    (e: ReactDragEvent<HTMLElement>): boolean => {
      return e.dataTransfer.items.length === 1 && shouldEnableDropHandler;
    },
    [shouldEnableDropHandler],
  );

  return (
    <DropHandler
      onDrop={onDrop}
      canBeDropped={canBeDropped}
      enabled={shouldEnableDropHandler}
      sx={{ border: "0px" }}
    >
      {children}
    </DropHandler>
  );
}
