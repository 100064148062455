import { FaroButton, FaroText, neutral } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/system";
import { OrthophotoPreview } from "./orthophoto-preview";
import { useExportOrthophoto } from "./use-export-orthophoto";

/** @returns the form to export an orthophoto from */
export function OrthophotoExportForm(): JSX.Element {
  const exportOrthophoto = useExportOrthophoto();

  return (
    <Stack justifyContent="space-between" height="100%">
      <Stack gap={2}>
        <FaroText variant="bodyM">
          To project points onto a different side, click on the desired face of
          the box.
        </FaroText>

        <FaroText variant="labelM">Orthophoto preview</FaroText>
        <OrthophotoPreview />
        <FaroText variant="bodyS" color={neutral[600]}>
          For faster processing, adjust the clipping box to show only a thin
          section before or after selecting the side you want to capture on the
          orthophoto.
        </FaroText>
      </Stack>

      <FaroButton
        onClick={exportOrthophoto}
        aria-label="start orthophoto export"
      >
        Export
      </FaroButton>
    </Stack>
  );
}
