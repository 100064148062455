import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

export type UiOverlayContextData = {
  /** The setter for the content of the overlay */
  setContent(content: JSX.Element | undefined): void;
};

export const UiOverlayContext = createContext<UiOverlayContextData | undefined>(
  undefined,
);

/**
 * @returns a context useful to set an element in the DOM in overlay on the app, while preserving all contexts
 */
export function useUiOverlayContext(): UiOverlayContextData {
  const context = useContext(UiOverlayContext);
  if (!context) {
    throw new Error("UiOverlayContext is not initialized.");
  }
  return context;
}

/**
 * @returns A custom UI element that can be rendered on top of the app
 */
export function UiOverlayContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const [content, setContent] = useState<JSX.Element | undefined>();

  const value = useMemo<UiOverlayContextData>(() => ({ setContent }), []);

  return (
    <UiOverlayContext.Provider value={value}>
      {content}
      {children}
    </UiOverlayContext.Provider>
  );
}
