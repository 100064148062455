import { EMPTY_ARRAY, GUID, includes } from "@faro-lotv/foundation";
import {
  IElementSection,
  IElementTimeSeries,
  isIElementSection,
  isIElementSectionDataSession,
  isIElementTimeseries,
} from "@faro-lotv/ielement-types";
import {
  isIElementVideoModeCopy,
  selectAncestor,
  selectIElement,
  selectIElementChildren,
  selectLegacyVideoRecordings,
} from "@faro-lotv/project-source";
import { IElementInVolume } from "@faro-lotv/service-wires";
import { AreaContentType } from "./area-contents-types";
import { RootState } from "./store";

/**
 * @returns the area contents for an area and type. Does not return full iElements, but only the id and type information.
 * @param areaId the area to get the contents for
 * @param type the type of area contents to get
 */
export function selectAreaContents(areaId: GUID, type: AreaContentType) {
  return (state: RootState): IElementInVolume[] =>
    state.areaContents.areaContents[areaId]?.[type] ?? EMPTY_ARRAY;
}

/**
 * @returns The list of data sessions in the volume of the current area. Returns dataSessions for datasets in the BI tree,
 * and dataSets for datasets in the capture tree.
 * @param area The input area
 */
export function selectAreaVolumeDataSessions(area: IElementSection) {
  return (state: RootState): IElementSection[] => {
    const dataSets = selectAreaContents(
      area.id,
      AreaContentType.captures,
    )(state);

    // Account for video recording still in a separate video recording timeseries
    // TODO: move into volume query https://faro01.atlassian.net/browse/SWEB-5950
    const dataSessions = selectLegacyVideoRecordings(area)(state);

    // Enumerate DataSets from the capture tree
    for (const { elementId } of dataSets) {
      const dataSetSection = selectIElement(elementId)(state);
      const dataSessionAncestor = selectAncestor(
        dataSetSection,
        isIElementSectionDataSession,
      )(state);

      if (!dataSetSection || !isIElementSection(dataSetSection)) {
        continue;
      }

      if (dataSessionAncestor) {
        dataSessions.push(dataSessionAncestor);
      } else {
        dataSessions.push(dataSetSection);
      }
    }

    return dataSessions;
  };
}

type AreaRoomsData = {
  /** The list of timeseries of rooms for the current area */
  roomsTimeSeries: IElementTimeSeries[];
  /** All the sections containing panos */
  roomsSections: IElementSection[];
};

/**
 * @returns The list of rooms time series and room sections of an area, not belonging to a data session
 * @param area The input area
 * @param excludeVideoModeClones true to exclude the rooms cloned by video mode trajectories
 */
export function selectAreaVolumeRoomsData(
  area: IElementSection,
  excludeVideoModeClones = true,
) {
  return (state: RootState): AreaRoomsData => {
    const roomsTimeSeries = selectAreaContents(
      area.id,
      AreaContentType.rooms,
    )(state)
      .map((e) => selectIElement(e.elementId)(state))
      .filter(
        (el): el is IElementTimeSeries => !!el && isIElementTimeseries(el),
      );

    const roomsSections = roomsTimeSeries
      .flatMap((room) => selectIElementChildren(room.id)(state))
      .filter(isIElementSection)
      .filter((el) => !excludeVideoModeClones || !isIElementVideoModeCopy(el));
    const allRoomsIds = roomsSections.map((section) => section.id);

    return {
      // Clean up rooms times series from timeseries containing only videomode clones
      roomsTimeSeries: roomsTimeSeries.filter((ts) =>
        ts.childrenIds?.some((id) => includes(allRoomsIds, id)),
      ),
      roomsSections,
    };
  };
}
